.team .banner {
  position: relative;
}
.team .banner img {
  width: 100%;
  height: 340px;
}
.team .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.team .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.team .aboutText .sj {
  font-size: 18px;
}
.team .content {
  padding: 30px 360px 60px;
}
.team .content .big {
  display: flex;
  margin-bottom: 30px;
}
.team .content .big .left {
  width: 290px;
  height: 310px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 3px 3px 8px rgba(168, 168, 168, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  letter-spacing: 3px;
}
.team .content .big .left .btn1 {
  width: 120px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  background-color: #2b68ff;
  border-radius: 5px;
  cursor: pointer;
}
.team .content .big .left .info1 {
  color: #2b68ff;
  cursor: pointer;
}
.team .content .big .left .img {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  overflow: hidden;
}
.team .content .big .left .img img {
  width: 100%;
  height: 100%;
}
.team .content .big .right {
  width: 850px;
  text-align: left;
  padding: 20px 50px;
  padding-right: 0 !important;
  box-sizing: border-box;
}
.team .content .big .right .name {
  font-size: 24px;
  color: #333333;
}
.team .content .big .right .line {
  width: 60px;
  border: 3px solid #2b68ff;
  margin: 25px 0;
}
.team .content .big .right .msg {
  font-size: 18px;
  color: #666;
  letter-spacing: 3px;
  line-height: 28px;
}
.team .content .big .right .msg > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.team .content .big .right .msg .num {
  margin-top: 20px;
}
.team .content .big .right .msg .bool {
  color: #000;
  margin-right: 10px;
}
.team .content .sm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.team .content .sm .boxList {
  display: flex;
  /* width: 50%; */
  margin-bottom: 30px;
}

.team .content .boxList .left {
  width: 290px;
  height: 310px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 3px 3px 8px rgba(168, 168, 168, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  letter-spacing: 3px;
}
.team .content .boxList .left .btn1 {
  width: 120px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  background-color: #2b68ff;
  border-radius: 5px;
  cursor: pointer;
}
.team .content .boxList .left .info1 {
  color: #2b68ff;
  cursor: pointer;
}
.team .content .boxList .left .img {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  overflow: hidden;
}
.team .content .boxList .left .img img {
  width: 100%;
  height: 100%;
}
.team .content .boxList .right {
  width: 235px;
  text-align: left;
  padding: 20px 50px;
  padding-right: 0 !important;

  box-sizing: border-box;
}
.team .content .boxList .right .name {
  font-size: 24px;
  color: #333333;
}
.team .content .boxList .right .line {
  width: 60px;
  border: 3px solid #2b68ff;
  margin: 25px 0;
}
.team .content .boxList .right .msg {
  font-size: 18px;
  color: #666;
  letter-spacing: 3px;
  line-height: 28px;
}
.team .content .boxList .right .msg .num {
  margin-top: 20px;
}
.team .content .boxList .right .msg .bool {
  color: #000;
}
.team .content .boxList .right .msg span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.team .content .boxList .right .msg .num div {
  display: flex;
}

.mobile .content {
  padding: 30 20 0;
}
.mobile .content .sm {
  display: flex;
  flex-wrap: wrap;
}
.mobile .content .sm .boxList {
  display: flex;
}
.mobile .content .sm .boxList {
  margin-bottom: 30px;
  background-color: #f8f8f8;
  box-shadow: 3px 3px 8px rgba(168, 168, 168, 0.3);
  padding: 0 10px 10px;
}

.mobile .content .boxList .left {
  width: 125px;
  height: 310px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  letter-spacing: 3px;
}
.mobile .content .boxList .left .btnBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile .content .boxList .left .btn1 {
  text-align: center;
  margin-bottom: 10px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background-color: #2b68ff;
  border-radius: 5px;
}
.mobile .content .boxList .left .info1 {
  color: #2b68ff;
}
.mobile .content .boxList .left .img {
  padding: 0;
  margin: 0;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  overflow: hidden;
}
.mobile .content .boxList .left .img img {
  width: 100%;
  height: 100%;
}
.mobile .content .boxList .right {
  text-align: left;
  padding: 10px 0 0 10px;
}
.mobile .content .boxList .right .name {
  font-size: 20px;
  color: #333333;
}
.mobile .content .boxList .right .line {
  width: 40px;
  border: 1px solid #2b68ff;
  margin: 10px 0;
}
.mobile .content .boxList .right .msg {
  font-size: 14px;
  color: #666;
  letter-spacing: 1px;
  line-height: 28px;
}
.mobile .content .boxList .right .msg .num {
  margin-top: 20px;
}
.mobile .content .boxList .right .msg .bool {
  color: #000;
}
.mobile .content .boxList .right .msg span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.mobile .content .boxList .right .msg .num div {
  display: flex;
}

.mobile .content .formBox {
  padding: 26px 30px;
  text-align: left;
  margin-bottom: 20px;
  box-shadow: 3px 3px 8px rgba(168, 168, 168, 0.3);
}
.mobile .content .formBox .title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}
.mobile .content .formBox .from {
  display: flex;
  flex-direction: column;
}
.mobile .content .formBox .from .item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.mobile .content .formBox .from .item .left {
  color: #666;
  font-size: 16px;
}
.mobile .content .formBox .from .item .right {
  height: 26px;
}
.mobile .content .formBox .from .item .right input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.mobile .content .formBox .from .item .right textarea {
  width: 100%;
  border-radius: 5px;
}
.mobile .content .formBox .from .item .textarea {
  height: auto;
}
.mobile .content .formBox .save {
  width: 217px;
  height: 48px;
  line-height: 48px;
  background-color: #7caaff;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}
.mobile {
  display: none;
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }
  .team {
    display: none;
  }
  .team .header {
    width: 100%;
  }
  .mobile .banner img {
    height: 150px !important;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .team {
    display: block;
  }
}

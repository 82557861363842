.ContactUs .banner {
  position: relative;
}
.ContactUs .banner img {
  width: 100%;
  height: 340px;
}
.ContactUs .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
  text-align: left;
}
.ContactUs .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.ContactUs .aboutText .sj {
  font-size: 18px;
}
.ContactUs .content {
  padding: 60px 360px;
  text-align: left;
  position: relative;
}
.ContactUs .content .formBox {
  width: 1200px;
  box-shadow: 12px 12px 27px 0px rgba(0, 0, 0, 0.27);
  display: flex;
}
.ContactUs .content .left .top {
  display: flex;
  align-items: center;
}
.ContactUs .content .formBox > .left {
  padding: 25px 30px;
  box-sizing: border-box;
}
.ContactUs .content .formBox .left .top span {
  font-size: 22px;
  margin-left: 10px;
}
.ContactUs .content .formBox .form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.ContactUs .content .formBox .form .item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.ContactUs .content .formBox .form .item input {
  width: 454px;
  height: 38px;
  margin-left: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.ContactUs .content .formBox .form .item select {
  width: 454px;
  border: 1px solid #d9d9d9;
  height: 38px;
  margin-left: 10px;
  border-radius: 5px;
}
.ContactUs .content .formBox .form .item textarea {
  width: 454px;
  height: 116px;
  margin-left: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.ContactUs .content .formBox .form .item .left {
}
.ContactUs .content .formBox .form .save {
  width: 217px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7caaff;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.ContactUs .content .formBox > .right {
  width: 600px;
  background-color: #2b68ff;
  color: #fff;
  padding: 45px 30px;
  box-sizing: border-box;
}
.ContactUs .content .formBox > .right .one {
  font-size: 30px;
}
.ContactUs .content .formBox > .right .two {
  font-size: 16px;
}
.ContactUs .content .formBox > .right .two span {
  font-size: 24px;
}
.ContactUs .content .formBox > .right .three {
  display: flex;
}

.ContactUs .content .formBox > .right .three .right {
  font-size: 16px;
}
.ContactUs .content .formBox > .right .three .right div {
}

@media (max-width: 768px) {
  .ContactUs .content {
    padding: 20px !important;
  }
  .ContactUs .content .formBox {
    width: auto !important;
    flex-direction: column !important;
  }
  .ContactUs .content .formBox .left .top span {
    font-size: 18px !important;
  }
  .ContactUs .content .formBox .form .item .left {
    font-size: 16px !important;
  }
  .ContactUs .content .formBox .form .item input,
  .ContactUs .content .formBox .form .item textarea {
    width: 100% !important;
  }
  .ContactUs .content .formBox > .right {
    width: auto !important;
    padding: 20px !important;
  }
  .ContactUs .content .formBox > .right .three .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px !important;
    margin-left: 20px !important;
  }
  .ContactUs #container {
    height: 200px !important;
  }
  .ContactUs .content .formBox > .right .three .right div {
  }
  .ContactUs .content .formBox .form .item .right {
    flex: 1 !important;
  }
  .ContactUs .content .formBox .form .item select {
    width: 100% !important;
    height: 38px !important;
  }
  .ContactUs .content .formBox > .left {
    padding: 20px !important;
    width: 100% !important;
  }
  .ContactUs .content .formBox .form .item {
    margin-bottom: 15px !important;
  }
  .ContactUs .content .formBox > .right .three img {
    width: 70px !important;
    height: 70px !important;
  }
  .ContactUs .content .formBox .form .item input {
    height: 38px !important;
  }
  .ContactUs .content .formBox .form .item textarea {
    height: 116px !important;
  }
  .ContactUs .content .formBox .form .save {
    width: 217px !important;
    height: 48px !important;
    line-height: 48px !important;
    font-size: 16px !important;
  }
  .ContactUs .content .formBox > .right .two {
    font-size: 12px !important;
  }
  .ContactUs .content .formBox > .right .two span {
    font-size: 14px !important;
  }
  .ContactUs .content .formBox > .right .one {
    font-size: 18px !important;
  }
  .ContactUs .banner img {
    height: 150px !important;
  }
  .ContactUs .aboutText {
    left: 10% !important;
  }
  .ContactUs .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .ContactUs .aboutText .sj {
    font-size: 16px !important;
  }
  .ContactUs .aboutText .sj div {
    margin: 0 !important;
  }
}

.caseInfo .banner {
  position: relative;
}
.caseInfo .banner img {
  width: 100%;
  height: 340px;
}
.caseInfo .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.caseInfo .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.caseInfo .aboutText .sj {
  font-size: 18px;
}
.caseInfo .content {
  padding: 60px 360px;
  text-align: left;
  position: relative;
}
.caseInfo .content .title {
  font-size: 40px;
  color: #333;
}
.caseInfo .content .num {
  margin: 20px 0;
  color: #626262;
}
.caseInfo .content .num,
.caseInfo .content .num div {
  display: flex;
  align-items: center;
}
.caseInfo .content .num div img {
  margin-right: 5px;
}
.caseInfo .content .num .time {
  margin-right: 20px;
}
.caseInfo .content .msg {
  font-size: 32px;
  color: #626262;
  letter-spacing: 2px;
}
.caseInfo .content .png3 {
  margin: 50px 0;
}
@media (max-width: 768px) {
  .caseInfo .content {
    padding: 20px !important;
  }
  .caseInfo .content .title {
    font-size: 16px !important;
  }
  .caseInfo .content .msg {
    font-size: 12px !important;
  }
  .caseInfo .content .png3 {
    width: 100% !important;
  }
  .caseInfo .content .num {
    display: flex !important;
  }
  .caseInfo .content .num div img {
    width: 12px !important;
  }
  .caseInfo .content .num div {
    font-size: 12px !important;
  }
  .caseInfo .banner img {
    height: 150px !important;
  }
  .caseInfo .aboutText {
    left: 10% !important;
  }
  .caseInfo .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .caseInfo .aboutText .sj {
    font-size: 16px !important;
  }
  .caseInfo .aboutText .sj div {
    margin: 0 !important;
  }
}

.cooperate .banner {
  position: relative;
}
.cooperate .banner img {
  width: 100%;
  height: 340px;
}
.cooperate .banner .aboutText {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translateY(-50%);
  color: #8f8f8f;
}
.cooperate .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.cooperate .aboutText .sj {
  font-size: 18px;
}
.cooperate .content {
  text-align: left;
}
.cooperate .content .box1 .left {
  padding: 75px 60px;
  position: relative;
  padding-right: 6px !important;
  width: 580px;
  box-sizing: border-box;
  background: #f1f8ff;
}
.cooperate .content .box1 .left .png3 {
  position: absolute;
  z-index: 9;
}
.cooperate .content .box1 .left .text {
  /* margin: 50px 28px; */
  font-size: 20px;
  color: #5a5a5a;
  padding-right: 0 !important;
  box-sizing: border-box;
}
.cooperate .content .box1 {
  display: flex;
  position: relative;
  top: -4px;
}
.cooperate .content .box1 .right {
  position: relative;
  flex: 1;
}
.cooperate .content .box1 .right img {
  width: 100%;
}
.cooperate .content .box1 .right .text {
  position: absolute;
  color: #fff;
  left: 242px;
  top: 215px;
  font-size: 50px;
  letter-spacing: 16px;
}
.cooperate .content .box1 .gs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 16px;
  color: #535353;
}
.cooperate .content .box1 .gs .img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  position: relative;
  border-radius: 4px;
}
.cooperate .content .box1 .gs .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cooperate .content .box1 .gs > div {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  width: 33%;
}
.cooperate .content .box1 .gs img {
  margin-right: 5px;
}
.cooperate .content .box2 {
  position: relative;
  top: -8px;
  display: flex;
}
.cooperate .content .box2 .text {
  /* position: absolute; */
  /* left: 70px; */
  /* top: 160px; */
  font-size: 50px;
  letter-spacing: 3px;
}
.cooperate .content .box2 .text div {
  margin-bottom: 10px;
  color: #4a4a4a;
}
.cooperate .content .box2 .right {
  background-color: #f1f8ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  /* text-align: center; */
}
.cooperate .content .box2 .right .div {
  width: 33%;
  font-size: 30px;
  color: #333;
}
.cooperate .content .box2 .right .div span {
  white-space: nowrap;
}
.cooperate .content .box3,
.cooperate .content .box4 {
  text-align: center;
  padding: 130px 356px;
}
.cooperate .content .box3 .title,
.cooperate .content .box4 .title,
.cooperate .content .box5 .title {
  font-size: 50px;
  color: #4a4a4a;
  letter-spacing: 3px;
  text-align: center;
}
.cooperate .content .box3 {
  position: relative;
  padding: 0;
}
.cooperate .content .box3 .text {
  position: absolute;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* height: 100%; */
  text-align: center;
}
.cooperate .content .box3 img,
.cooperate .content .box3 .zz {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cooperate .content .box3 .msg {
  font-size: 20px;
  color: #757575;
}
.cooperate .content .box4 {
  padding-top: 0;
}
.cooperate .content .box4 .title {
  /* margin-bottom: 47px; */
}
.cooperate .box4 .msg {
  display: flex;
  justify-content: space-between;
}
.cooperate .box4 .msg div {
  display: flex;
  flex-direction: column;
}
.cooperate .content .box4 .msg .top,
.cooperate .content .box4 .msg .bottom {
  display: flex;
}
.cooperate .content .box4 .msg .div {
  display: flex;
  margin-bottom: 70px;
  font-size: 26px;
  color: #333333;
  align-items: center;
}
.cooperate .content .box4 .msg .div img {
  margin-right: 25px;
}
.cooperate .content .box5 {
  /* padding: 0 140px; */
  background: #f8f8f8;
}
.cooperate .content .box5 .boxList {
  /* display: flex; */
  /* justify-content: space-between; */
}
.cooperate .content .box5 .boxList .box {
  position: relative;
}
.cooperate .content .box5 .boxList .box .text {
  height: 120px;
  position: absolute;
  bottom: 0;
  font-size: 20px;
  color: #fff;
  padding: 27px 20px;
}
@media (max-width: 768px) {
  .cooperate .banner .aboutText {
    left: 10% !important;
  }
  .cooperate .box4 .msg div {
    display: none;
  }
  .cooperate .content .box1 .left {
    padding: 20px !important;
    width: 100% !important;
  }
  .cooperate .content .box1 .left .text {
    width: 100% !important;
  }
  .cooperate .content .box1 .gs {
    display: none;
  }
  .cooperate .content .box1 .left .png3,
  .cooperate .content .box1 .right,
  .cooperate .content .box2 .left {
    display: none !important;
  }
  .cooperate .content .box2 .right {
    padding: 12px !important;
  }
  .cooperate .content .box2 .right .div {
    font-size: 11px !important;
    margin-bottom: 10px !important;
  }
  .cooperate .content .box3,
  .cooperate .content .box4 {
    padding: 20px !important;
  }
  .cooperate .content .box3 .title,
  .cooperate .content .box4 .title,
  .cooperate .content .box5 .title {
    margin-bottom: 20px !important;
    font-size: 30px !important;
  }
  .cooperate .content .box3 .msg {
    font-size: 16px !important;
  }
  .cooperate .content .box4 .msg .div img {
    display: none !important;
  }
  .cooperate .box4 .msg {
    flex-wrap: wrap;
    justify-content: center !important;
    margin-left: 30px;
  }
  .cooperate .content .box4 .msg .div {
    padding: 0 !important;
    font-size: 14px !important;
    /* justify-content: n; */
    justify-content: unset;
    flex-direction: inherit;
    flex-wrap: wrap;
    margin-right: 28px;
    width: 100px;
    margin-bottom: 20px !important;
    line-height: normal !important;
  }
  .cooperate .content .box5 {
    padding: 20px !important;
  }
  .cooperate .content .box5 .boxList {
    flex-direction: column !important;
  }
  .cooperate .content .box5 .boxList .box img {
    width: 100% !important;
    height: auto !important;
  }
  .cooperate .content .box5 .boxList .box {
    margin-bottom: 10px !important;
  }
  .cooperate .content .box5 .boxList .box .text {
    font-size: 16px !important;
    height: 110px !important;
    padding: 10px !important;
  }
  .cooperate .content .box1 .left .text,
  .cooperate .content .box1 .gs {
    font-size: 16px !important;
  }
  .cooperate .content .box1 .gs img {
    width: auto !important;
  }
  .cooperate .banner img {
    height: 150px !important;
  }
  .cooperate .aboutText .gy {
    font-size: 16px !important;
    height: 21px !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  .cooperate .aboutText .sj {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .cooperate .aboutText .sj div {
    margin: 0 !important;
  }
  .cooperate .content .box1 {
    padding: 20px !important;
  }
  .cooperate .content .box2 {
    padding: 20px !important;
  }
  .cooperate .content .box2 .right {
    width: 100% !important;
  }
  .cooperate .content .box2 .text {
    font-size: 18px !important;
    text-align: center;
  }
  .cooperate .jl {
    margin-top: 20px !important;
    flex-direction: column;
    align-items: center;
  }
  .cooperate .content .box2 .right .div {
    width: auto !important;
  }
  .cooperate .content .box2 .right .div img {
    margin-right: 15px !important;
  }
  .cooperate .content .box3 .title {
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
  .cooperate .content .box3 .msg {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .cooperate .content .box3 .msg span {
    font-size: 16px !important;
  }
  .cooperate .content .box3 .text {
    width: 300px !important;
  }
  .cooperate .content .box3 {
    height: 100px !important;
  }
  .cooperate .content .box1 .left .text,
  .cooperate .content .box4 .title,
  .cooperate .content .box5 .title {
    font-size: 18px !important;
  }
  .cooperate .content .box4 {
    padding-bottom: 0 !important;
  }
  .cooperate .box5 {
    display: none;
  }
  .cooperate .box5 .boxList > div .left {
    display: none;
  }
  .cooperate .box5 .boxList > div .right {
    width: 100% !important;
  }
  .cooperate .box5 .boxList > div .right > div {
    width: 100% !important;
  }
}

.HeadDiv {
  width: 100%;
  position: relative;
}
p {
  padding: 0;
  margin: 0;
}
.lunbo {
  /* position: absolute; */
}
.zx {
  width: 40px;
  height: 146px;
  background: linear-gradient(270deg, #5875f7 0%, #7b96f4 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: fixed;
  right: 20px;
  z-index: 77;
  top: 50%;
  border-radius: 6px;
}
.zx a {
  text-decoration: none;

  writing-mode: vertical-rl; /* 从右到左 */
  text-orientation: upright; /* 保持正常显示 */
  color: #fff;
  letter-spacing: 3px;
}
.zx img {
  width: 20px;
  height: 20px;
}
.HeadDivBg {
  width: 100%;
  height: auto;
  display: block;
  /* position: absolute; */
  /* background-image: url('./img/bg.png');
    background-repeat: no-repeat;
    background-size: cover; */
}
.box1 {
  position: relative;
  z-index: 8;
}
.box1 .lunboText {
  text-align: left;
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.box1 .lunboText div {
  margin-bottom: 10px;
}
.box1 .lunboBtn {
  border: 2px solid #fff;
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.HeadDivBgicon {
  display: block;
  position: absolute;
  z-index: 9;
}

.HeadDivBgicon1 {
  display: block;
  position: absolute;
  cursor: pointer;
}

.HeadDivlist {
  display: block;
  position: absolute;
  color: white;
  font-size: 12px;
  z-index: 9;
}
.lunboBtn,
.wentiList .wenti .to {
  cursor: pointer;
}
.HeadDivlistItem {
  margin-left: 19px;
}
.HeadDiv .form {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 5px;
}
.HeadDiv .form .table {
  letter-spacing: 1px;

  width: 460px;
  height: 192px;
  border-radius: 5px;
  border: 1px solid #2b68ff;
}
.HeadDiv .form .table .top {
  height: 48px;
  line-height: 48px;
  background-color: #2b68ff;
  color: #fff;
  border-radius: 5px 5px 0 0;
}
.HeadDiv .form .table .tabletr {
  height: calc(100% - 48px);
  color: #2b68ff;
  /* overflow-y: scroll; */
  overflow: hidden;
}
.HeadDiv .form .table .tabletr .tr {
  margin-bottom: 10px;
}
.HeadDiv .form .form .name,
.HeadDiv .form .form .ls {
  display: flex;
  justify-content: space-between;
}
.HeadDiv .form .form .name div,
.HeadDiv .form .form .ls div {
  width: 200px;
  height: 48px;
  line-height: 48px;
  border-radius: 5px;
  border: 1px solid #2b68ff;
  color: #2b68ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeadDiv .form {
  width: 418px;
  height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HeadDiv .form .name,
.HeadDiv .form .ls {
  display: flex;
  justify-content: space-between;
}
.HeadDiv .form .name div,
.HeadDiv .form .ls div {
  width: 200px;
  height: 48px;
  line-height: 48px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
}

.HeadDiv .form .save div {
  width: 418px;
  height: 48px;
  line-height: 48px;
  border-radius: 5px;
  background-color: #2b68ff;
  color: #fff;
  cursor: pointer;
}
.HeadDiv .form .name div input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: #2b68ff;
  text-align: center;
}
.HeadDiv .form .name div input::placeholder {
  color: #2b68ff;
}
.HeadDiv .form .ls div {
  padding-right: 10px;
  box-sizing: border-box;
}
.HeadDiv .form .ls div select {
  height: 100%;
  background-color: transparent;
  color: #2b68ff;
  text-align: center;
  border: none;
  padding: 0;
}
.HeadDiv .form .ls div select option {
  color: #000;
}
.HeadDiv .form .sub {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.wentiList {
  text-align: left;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}
.wentiList .wenti {
  position: relative;
  box-sizing: border-box;
}
.lkjasdf {
  position: relative;
  z-index: 9;
}
.wentiList .wenti > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wentiList .wenti .msg {
  color: #5a5a5a;
}
.wentiList .wenti .to {
  color: #2b68ff;
  display: flex;
  align-items: center;
  border: 1px solid #2b68ff;
  text-align: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
}
.HeadDivBg1 {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}
.HeadDivBg2 {
  /* background-image: url('./img/4.png'); */
  display: block;
  position: relative;
  height: auto;
}
.HeadDivBg3 {
  width: 100%;
  height: auto;
}

.HeadDivBg4 {
  width: 100%;
  /* height: auto; */
  display: block;
  position: relative;
  /* background-color: red; */
}
.HeadDivBg5 {
  /* width: 100%; */
  display: flex;
  /* background-color: black; */
}
.HeadDivBg8 {
  width: 100%;
  height: auto;
}
div.HeadDivBg9 {
  margin-left: 10px;
}
.HeadDivBg9 {
  display: inline-block;
}
.HeadDivBg10 {
  display: block;
}

.HeadDivBg11 {
  padding: 10px;
  text-align: left;
}
.imgtop {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.anli {
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.HeadDivBg7 {
  display: inline-block;
}
.HeadDivBg6 {
  display: flex;
}
.HeadDivBg12 {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  color: #999999;
}
.hez {
  color: #999999;
}
.HeadDivBg13 {
  text-align: left;
  display: flex;
}
.HeadDivBg14 {
  display: inline-block;
  position: relative;
}
.HeadDivBg15 {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  position: absolute;
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.HeadDivBg15 div {
  margin-left: 10px;
  color: #fff;
}
.color33 {
  color: #333;
  margin-bottom: 5px;
}
.HeadDivBg16 {
  display: inline-block;
}
.HeadDivBg17 {
  display: inline-block;
}
.HeadDivBg18 {
  display: block;
}
.HeadDivBg19 {
  display: inline-block;
}
.HeadDivBg20 {
  overflow: hidden;
  font-size: 12px;
  color: #999;
}
.xinwen {
  position: relative;
}
.xinwen .bgimg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.xinwen .bgimg img {
  width: 100%;
  height: 100%;
}
.xinwen .top {
  position: relative;
  z-index: 9;
}
.imgBox123 {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  text-align: left;
}
.imgBox123 .left,
.imgBox123 .right {
  background-color: #fff;
  box-sizing: border-box;
}
.imgBox123 .left .top,
.imgBox123 .right .left {
  background-color: #b2b2b2;
}
.imgBox123 .title {
  color: #4a4a4a;
  margin-bottom: 10px;
}
.imgBox123 .msg {
  color: #757575;
}
.imgBox123 .boxList {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.imgBox123 .boxList .box {
  display: flex;
}
.laojie {
  border: 1px solid #fff;
  color: #fff;
  /* border-radius: 50px; */
  text-align: center;
  position: relative;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.lunboBox {
  position: relative;
  top: -50px;
  z-index: 1;
  /* display: none; */
}
.imgBox123 .msg1 {
  color: #757575;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.imgBox123 .msg {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.color33,
.anli,
.title321 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
@media (max-width: 768px) {
  .num,
  .my,
  .gy .right,
  .ys .ysimg {
    display: none !important;
  }
  .gy {
    box-sizing: border-box;
    padding: 20px !important;
  }
  .ly > .text {
    width: 70px !important;
  }
  .ly > div {
    width: 100% !important;
    height: auto !important;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .ly .ss {
    width: 50%;
  }
  .ly .ss img {
    width: 30px !important;
    height: auto !important;
  }
  .ly .ss div {
    font-size: 16px !important;
  }
  .ly .ss .msggg {
    font-size: 10px !important;
    width: auto !important;
    margin-bottom: 15px;
  }

  .ys {
    height: auto !important;
    padding-bottom: 20px;
  }
  .ys .HeadDivBg1 {
    width: 70px !important;
  }
  .ys > div > div > div > img {
    display: none;
  }
  .ys > div > div > div > div {
    width: 120px !important;
  }
  .ys > div > div > div > div > div:first-child {
    font-size: 10px !important;
    background-color: transparent !important;
    margin-left: 10px;
  }
  .ys > div > div > div > div > div:last-child {
    font-size: 10px !important;
  }
  .ys > div > div > div > div > div img {
    width: 20px !important;
  }
  .ys > div > div {
    left: auto !important;
    margin-left: 50px;
  }
  .gy .left {
    width: 100% !important;
    padding: 20px;
  }
  .gy .left .title {
    font-size: 18px !important;
  }
  .gy .left .title1 {
    font-size: 12px !important;
  }
  .gy .left .msg {
    font-size: 12px !important;
  }
  .aaa,
  .lunboBox {
    display: block;
    top: 0 !important;
  }
  .ls > img {
    width: 70px !important;
  }

  .xinwen {
    display: none;
  }
  .HeadDivBg4 {
    display: none;
  }
  .HeadDiv .form {
    display: none;
  }
  .HeadDivBg1 > img {
    width: 160px !important;
    margin-top: 20px;
  }
  .wentiList {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .wenti {
    width: 100% !important;
    height: 260px !important;
    padding: 20px !important;
  }
  .wentiList .wenti .to {
    width: auto !important;
    height: 26px !important;
    line-height: 26px !important;
    border: none !important;
    bottom: 25px !important;
  }
  .wentiList .wenti .to img {
    width: auto !important;
    height: auto !important;
  }
  .wenti .title {
    font-size: 16px !important;
  }
  .wenti .msg {
    font-size: 14px !important;
  }
  .wenti .to {
    font-size: 14px !important;
  }
  .none {
    display: none;
  }
  .lkjasdf {
    text-align: center;
  }

  .lunboText {
    width: 58%;
    font-size: 14px !important;
    right: 20px !important;
  }
  .box1 .lunboBtn {
    width: 80px !important;
    height: 25px !important;
    line-height: 25px !important;
    bottom: 20px !important;
    left: 71% !important;
    transform: none !important;
    font-size: 14px;
  }
}

.formBo {
  padding: 26px 30px;
  margin-top: 70px;
  text-align: left;
  box-shadow: 12px 12px 27px 0 rgba(0, 0, 0, 0.3);
}
.formBo .title {
  margin-bottom: 16px;
  text-align: left !important;
}
.formBo .from {
  display: flex;
  flex-direction: column;
}
.formBo .from .item {
  display: flex;
  margin-bottom: 15px;
}
.formBo .from .item .left {
  width: 330px;
  text-align: right;
  color: #333;
  font-size: 16px;
  margin-right: 16px;
}
.formBo .from .item .right {
  height: 38px;
  width: 540px;
}
.formBo .from .item .right input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.formBo .from .item .right textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  height: 100%;
}
.formBo .from .item .textarea {
  height: auto;
}
.formBo .save {
  width: 217px;
  height: 48px;
  line-height: 48px;
  background-color: #7caaff;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .formBo {
    padding: 26px 30px !important;
    text-align: left !important;
    margin-bottom: 20px !important;
    box-shadow: 3px 3px 8px rgba(168, 168, 168, 0.3) !important;
  }
  .formBo .title {
    font-size: 22px !important;
    font-weight: bold !important;
    margin-bottom: 16px !important;
  }
  .formBo .from {
    display: flex;
    flex-direction: column;
  }
  .formBo .from .item {
    flex-direction: row;
    width: auto !important;
  }
  .formBo .from .item .left {
    margin-right: 10px !important;
    font-size: 16px !important;
    width: 330px !important;
  }

  .formBo .from .item .right {
    height: 26px !important;
    width: 540px !important;
  }
  .formBo .from .item .right input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }
  .formBo .from .item .right textarea {
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }
  .formBo .from .item .textarea {
    height: auto !important;
  }
  .formBo .save {
    width: 217px !important;
    height: 48px !important;
    line-height: 48px !important;
    background-color: #7caaff;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px !important;
    font-size: 18px !important;
  }
}

.headerer .header {
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 114px 0 360px;
  background-color: #000;
  color: #fff;
}
.headerer {
  position: relative;
  z-index: 9;
}

.headerer .bgc {
  background-color: transparent;
}
.headerer .header .HeadDiv,
.headerer .header .left {
  width: 237px;
  height: 25px;
}
.headerer .header .right {
  width: 868px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
}
.headerer .header .right span {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.headerer .header .active {
  /* padding-bottom: 10px; */
  border-bottom: 3px solid #fce38a !important;
  color: #fce38a;
}
.mobile {
  display: none;
}
.mobile .header {
  height: 84px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #000;
  position: relative;
  z-index: 11;
}
.mobile .HeadDiv {
  width: 237px;
  height: 25px;
}
.mobile .navList {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  z-index: 15;
}
.mobile .isShow {
  display: block;
}
.mobile .navList {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 15;
}
.mobile .navList li {
  background-color: #000;
  color: #fff;

  padding: 5px 0;
}
.mobile .navList .active {
  color: #fff;
  background-color: #353333;
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }
  .headerer {
    display: none;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .headerer {
    display: block;
  }
}

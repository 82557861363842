.case .banner {
  position: relative;
}
.case .banner img {
  width: 100%;
  height: 340px;
}
.case .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.case .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.case .aboutText .sj {
  font-size: 18px;
}
.case .content {
  padding: 60px 360px;
  text-align: left;
  position: relative;
}
.case .content .title {
  font-size: 38px;
  text-align: center;
}
.case .content .title span {
  color: #2b68ff;
}
.case .content .type {
  font-size: 18px;
  padding-left: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #000;
}
.case .content .type select {
  font-size: 18px;
  border: none;
  margin-left: 15px;
  width: 100px;
  padding: 0;
}
.case .content .type select option {
  font-size: 16px;
}
.case .content .boxList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.case .content .box {
  width: 380px;
  height: 435px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  position: relative;
}
.case .content .box > img {
  height: 214px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.case .content .box .title {
  font-size: 22px;
  box-sizing: border-box;
  padding-bottom: 0 !important;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.case .content .box .msg {
  box-sizing: border-box;
  font-size: 18px;
  color: #666;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  height: 114px;
}
.case .content .box .bottm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0 !important;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.case .content .box .bottm .category {
  padding: 0 5px;
  height: 32px;
  line-height: 32px;
  background-color: #f7f9fa;
  border-radius: 5px;
  text-align: center;
  max-width: 180px;
  overflow: hidden;
}
.case .content .dn {
  opacity: 0;
}
.case .content .lookMore {
  width: 215px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #2b68ff;
  color: #2b68ff;
  font-size: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  margin-bottom: 87px;
  cursor: pointer;
}

.case .xz {
  position: relative;
  padding-top: 1px;
  text-align: center;
  height: 650px;
}
.case .xz > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.case .xz .title {
  position: relative;
  z-index: 9;
  text-align: center;
  color: #fff;
}
.case .xz .box111 {
  position: relative;
  z-index: 9;
  width: 1684px;
  height: 471px;
  padding: 60px 97px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.case .xz .box img {
  width: 100%;
}
@media (max-width: 768px) {
  .case .content {
    padding: 20px !important;
  }
  .case .content .title {
    font-size: 20px !important;
  }
  .case .content .type {
    font-size: 14px !important;
    margin-top: 10px !important;
  }
  .case .content .type select {
    border: none !important;
    width: 100px !important;
    font-size: 14px !important;
  }
  .case .content .type select option {
    font-size: 14px !important;
  }
  .case .content .boxList {
    flex-direction: column !important;
  }
  .case .content .box,
  .case .content .box > img {
    width: 100% !important;
    height: auto !important;
  }
  .case .content .box .title {
    padding-top: 10px !important;
    line-height: normal !important;
    max-height: 65px !important;
    padding: 10px !important;
    height: 40px !important;
  }
  .case .content .dn,
  .case .xz {
    display: none !important;
  }
  .case .content .box .bottm {
    height: 40px;
    padding: 10px !important;
  }
  .case .content .lookMore {
    width: 215px !important;
    height: 48px !important;
    line-height: 48px !important;
    border-radius: 10px !important;
    font-size: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 87px !important;
  }
  .case .content .box .msg {
    font-size: 16px !important;
    height: auto !important;
    margin-bottom: 40px;
    padding: 10px !important;
    padding-bottom: 0 !important;
  }
  .case .content .box .bottm .category {
    height: auto !important;
    line-height: normal !important;
  }
  .case .content .box {
    margin-bottom: 10px !important;
  }
  .case .banner img {
    height: 150px !important;
  }
  .case .aboutText {
    left: 10% !important;
  }
  .case .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .case .aboutText .sj {
    font-size: 16px !important;
  }
  .case .aboutText .sj div {
    margin: 0 !important;
  }
}

.newsInfo .banner {
  position: relative;
}
.newsInfo .banner img {
  width: 100%;
  height: 340px;
}
.newsInfo .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.newsInfo .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.newsInfo .aboutText .sj {
  font-size: 18px;
}
.newsInfo .content {
  padding: 70px 360px;
  text-align: left;
}
.newsInfo .content .title1 {
  font-size: 40px;
  color: #333333;
  margin-bottom: 30px;
}
.newsInfo .content .time {
  font-size: 28px;
  color: #626262;
  margin-bottom: 30px;
}
.newsInfo .content .msg,
.newsInfo .content .creat {
  font-size: 32px;
  color: #626262;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .newsInfo .content {
    padding: 20px;
    letter-spacing: 2px;
  }
  .newsInfo .content .title1 {
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  .newsInfo .content .time,
  .newsInfo .content .msg,
  .newsInfo .content .creat {
    font-size: 12px !important;
    margin-bottom: 10px;
  }
  .newsInfo .banner img {
    height: 150px !important;
  }
  .newsInfo .aboutText {
    left: 10% !important;
  }
  .newsInfo .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .newsInfo .aboutText .sj {
    font-size: 16px !important;
  }
  .newsInfo .aboutText .sj div {
    margin: 0 !important;
  }
}

.NewsCenter {
  position: relative;
}
.NewsCenter .banner {
  position: relative;
}
.NewsCenter .banner img {
  width: 100%;
  height: 340px;
}
.NewsCenter .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
}
.NewsCenter .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.NewsCenter .aboutText .sj {
  font-size: 18px;
}
.NewsCenter .content {
  width: 1360px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: #fff;
  top: -100px;
  padding: 50px 80px;
  padding-bottom: 0 !important;
  text-align: left;
}
.NewsCenter .content .big {
  display: flex;
}
.NewsCenter .content .big .left {
  margin-right: 50px;
  position: relative;
  width: 781px;
  height: 337px;
}
.NewsCenter .content .big .left img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.NewsCenter .content .big .left .text {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #fff;
}
.NewsCenter .content .big .left .text .bigText {
  font-size: 24px;
  margin-bottom: 10px;
}
.NewsCenter .content .big .right {
  flex: 1;
}
.NewsCenter .content .time {
  font-size: 14px;
  color: #2b68ff;
}
.NewsCenter .content .newsList {
}
.NewsCenter .content .title {
  font-size: 24px;
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  height: 56px;
}
.NewsCenter .content .big .right .lineBox {
  border-bottom: 1px solid #999;
  margin-bottom: 12px;
}
.NewsCenter .content .msg {
  font-size: 18px;
  color: #666666;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.NewsCenter .content .news {
  border-bottom: 1px solid #999;
  margin-bottom: 12px;
  padding-bottom: 28px;
}
.NewsCenter .content .news .title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.NewsCenter .content .pager {
  justify-content: center;
  font-size: 18px;
  margin-top: 40px;
}
.NewsCenter .content .pager,
.NewsCenter .content .pager .num,
.NewsCenter .content .pager .dao {
  display: flex;
  align-items: center;
}

.NewsCenter .content .pager .total {
  font-size: 18px;
}
.NewsCenter .content .pager .num .box {
  border: 1px solid #dadada;
  padding: 10px 20px;
  font-size: 18px;
}
.NewsCenter .content .pager .num .active {
  color: #fff;
  background-color: #2b68ff;
}
.NewsCenter .content .pager .dao {
  color: #666;
}
.NewsCenter .content .pager .dao .box1 {
  color: #000;
  border: 1px solid #dadada;
  width: 50px;
  height: 40px;
}
.NewsCenter .content .pager .dao .box1 input {
  border: none;
  width: 20px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  display: inline-block;
  text-align: center;
}

.NewsCenter .content .pager .qr {
  padding: 10px 20px;
  color: #fff;
  background-color: #2b68ff;
  cursor: pointer;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .NewsCenter .content {
    width: auto !important;
    padding: 20px !important;
    top: 0 !important;
  }
  .NewsCenter .content .big,
  .NewsCenter .content .pager .qr,
  .NewsCenter .content .pager .dao,
  .NewsCenter .content .pager .total {
    display: none !important;
  }

  .NewsCenter .content .pager .num .box {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .NewsCenter .content .title {
    font-size: 24px !important;
    margin-bottom: 12px !important;

    height: 30px !important;
    line-height: normal !important;
  }
  .NewsCenter .content .msg {
    font-size: 18px !important;
  }
  .NewsCenter .content .time {
    font-size: 12px !important;
    line-height: normal !important;
  }
  .NewsCenter .content .news {
    margin-bottom: 15px !important;
  }
  .NewsCenter .banner img {
    height: 150px !important;
  }
  .NewsCenter .aboutText {
    left: 10% !important;
  }
  .NewsCenter .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .NewsCenter .aboutText .sj {
    font-size: 16px !important;
  }
  .NewsCenter .aboutText .sj div {
    margin: 0 !important;
  }
}

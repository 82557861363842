.legalServiceInfo .banner {
  position: relative;
}
.legalServiceInfo .banner img {
  width: 100%;
  height: 340px;
}
.legalServiceInfo .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.legalServiceInfo .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.legalServiceInfo .aboutText .sj {
  font-size: 18px;
}
.legalServiceInfo .content {
  padding: 100px 360px;
  text-align: left;
}
.legalServiceInfo .content .type > .title {
  font-size: 50px;
  letter-spacing: 3px;
  color: #4a4a4a;
  margin-bottom: 80px;
  text-align: center;
}
.legalServiceInfo .content .typeBox {
  display: flex;
  justify-content: space-between;
}
.legalServiceInfo .content .typeBox .left {
  width: 570px;
}
.legalServiceInfo .content .typeBox .left .img {
  width: 570px;
  height: 380px;
}
.legalServiceInfo .content .typeBox .left .img img {
  width: 100%;
  height: 100%;
}
.legalServiceInfo .content .typeBox .right {
  width: 600px;
  border: 0.5px solid rgba(0, 0, 0, 0.688);
  box-sizing: border-box;
  letter-spacing: 3px;
}
.legalServiceInfo .content .typeBox .right .top {
  padding: 15px;
  display: flex;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.688);
}
.legalServiceInfo .content .typeBox .right .top .title {
  margin-left: 15px;
  font-size: 24px;
}
.legalServiceInfo .content .typeBox .right .bottom {
  padding: 15px;
  font-size: 20px;
}
@media (max-width: 768px) {
  .legalServiceInfo .banner img {
    height: 150px !important;
  }
  .legalServiceInfo .aboutText {
    left: 10% !important;
  }
  .legalServiceInfo .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .legalServiceInfo .aboutText .sj {
    font-size: 16px !important;
  }
  .legalServiceInfo .aboutText .sj div {
    margin: 0 !important;
  }
  .legalServiceInfo .content {
    padding: 20px !important;
  }
  .legalServiceInfo .content .type > .title {
    font-size: 26px !important;
    margin-bottom: 20px !important;
  }
  .legalServiceInfo .content .typeBox {
    flex-direction: column !important;
  }
  .legalServiceInfo .content .typeBox .left {
    width: auto !important;
  }
  .legalServiceInfo .content .typeBox .left img {
    width: 100% !important;
    height: 100% !important;
  }
  .legalServiceInfo .content .typeBox .right {
    width: auto !important;
  }
  .legalServiceInfo .content .typeBox .right .top {
    padding: 10px !important;
  }
  .legalServiceInfo .content .typeBox .right .bottom {
    padding: 10px !important;
    font-size: 14px !important;
  }
}

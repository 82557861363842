.legalService .banner {
  position: relative;
}
.legalService .banner img {
  width: 100%;
  height: 340px;
}
.legalService .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #8f8f8f;
}
.legalService .aboutText .gy {
  font-size: 30px;
  padding-bottom: 20px;
}
.legalService .aboutText .sj {
  font-size: 18px;
}
.legalService .content {
  padding: 60px 360px;
}
.legalService .content .boxList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.legalService .content .boxList .dn {
  opacity: 0;
}
.legalService .sli {
}
.legalService .content .boxList .box11 {
  width: 380px;
  height: 337px;
  position: relative;
  box-shadow: 7px 7px 16px 4px rgba(158, 213, 255, 0.25);
  margin-bottom: 40px;
  /* padding: 26px 24px; */
}
.legalService .content .boxList .box11.active {
  border: 1px solid #2b68ff;
  box-sizing: border-box;
  border-radius: 5px;
}
.legalService .content .boxList .box11 > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.legalService .content .boxList .box11 .title,
.legalService .content .boxList .box11 .msg,
.legalService .content .boxList .box11 .btn111 {
  position: relative;
  z-index: 9;
}
.legalService .content .boxList .box11 .title {
  font-size: 28px;
  text-align: center;
  box-sizing: border-box;
}
.legalService .content .boxList .box11 .msg {
  font-size: 20px;
  padding: 24px;
  padding-top: 10px;
  text-align: left;
}

.legalService .content .boxList .box11 .div {
  height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
}
.legalService .content .boxList .box11 .div p {
  margin: 0;
  padding: 0;
}
.legalService .content .boxList .box11 .line {
  height: 1px;
  background: #dcdcdc;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.legalService .content .boxList .box11 .btn111 {
  text-align: center;
  background-color: #fff;
  border: 1px solid #1f4ac4;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: #1f4ac4;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.legalService .content .boxList .active .title {
  background-color: #2b68ff;
  color: #fff;
}
.legalService .content .boxList .active .btn111 {
  background-color: #2b68ff;
  color: #fff;
}

@media (max-width: 768px) {
  .legalService .content {
    padding: 20px !important;
  }
  .legalService .content .boxList .box11 {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
  }

  .legalServiceInfo .content .typeBox .left .img {
    width: auto !important;
  }
  .legalService .content .boxList .box11 .title {
    font-size: 20px !important;
    height: auto !important;
    padding: 26px 0 20px 0 !important;
  }
  .legalService .content .boxList .box11 .msg {
    font-size: 20px !important;
    padding: 24px !important;
    padding-top: 10px !important;
    text-align: left !important;
    line-height: 34px !important;
  }
  .legalService .content .boxList .box11 .div {
    height: 100px !important;
  }
  .legalService .content .boxList .active .btn111 {
    width: 120px !important;
  }
  .legalService .content .boxList .box11 .btn111 {
    width: 100px !important;
    height: 32px !important;
    line-height: 32px !important;
    font-size: 18px !important;
    margin-top: 10px !important;
  }
  .legalService .banner img {
    height: 150px !important;
  }
  .legalService .aboutText {
    left: 10% !important;
  }
  .legalService .aboutText .gy {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 21px !important;
    line-height: normal !important;
  }
  .legalService .aboutText .sj {
    font-size: 16px !important;
  }
  .legalService .aboutText .sj div {
    margin: 0 !important;
  }
  .legalService .content .boxList .box11 .line {
    width: 80% !important;
  }
}

.about {
  width: 100%;
  height: 100%;
}

.about .banner {
  position: relative;
}
.about .banner img {
  width: 100%;
}
.about .banner .aboutText {
  position: absolute;
  z-index: 9;
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-50%); */
  transform: translateY(-50%);
  text-align: left;
  color: #fff;
}
.about .aboutText .gy {
  font-size: 30px;
}
.about .aboutText .sj {
  font-size: 18px;
}
.about .content {
  text-align: left;
  padding: 60px 360px 0;
  position: relative;
}
.about .content .title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 34px;
}
.about .msg,
.about .num,
.about .img {
  position: relative;
}
.about .content p {
  font-size: 18px;
  /* padding-bottom: 20px; */
  margin: 0;
  color: #2a2a2a;
}
.about .content .num {
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
}
.about .content .num .line {
  width: 34px;
  height: 63px;
}
.about .content .num .box {
  /* width: 211px; */
  text-align: center;
}
.about .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.about .content .num .box .one {
  letter-spacing: 3px;

  font-size: 46px;
  font-weight: 600;
  color: #2c69ff;
}
.about .content .num .box .two {
  letter-spacing: 2px;

  margin-top: 10px;
  font-size: 14px;
}
.about .content .num .box .one span {
  display: inline-block;
  font-weight: 600;
  /* font-size: 14px; */
}
.about .content .img {
  margin-top: 76px;
  /* margin-bottom: 93px; */
  display: flex;
  justify-content: space-between;
}
.about .content .img img {
  width: 388px;
  height: 250px;
}
.mobile {
  width: 100%;
}

.mobile .HeadDiv {
  /* width: 237px;
  height: 25px; */
}
/* .mobile .navList {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.mobile .navList {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 9;
}
.mobile .navList li {
  background-color: rgba(71, 122, 251, 1);
  color: #fff;

  padding: 5px 0;
}
.mobile .navList .active {
  color: #fff;
  background-color: rgb(23, 83, 235);
} */
.mobile .banner {
  position: relative;
}
.mobile .banner img {
  width: 100%;
}
.mobile .banner .aboutText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  text-align: left;
  color: #fff;
}
.mobile .aboutText .gy1 {
  font-size: 30px;
  padding-bottom: 10px;
}
.mobile .aboutText .sj {
  font-size: 16px;
}
.mobile .content {
  text-align: left;
  padding: 60px 20px 0;
}
.mobile .content .title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 34px;
}
.mobile .content p {
  font-size: 18px;
  letter-spacing: 2px;
  padding-bottom: 20px;
}
.mobile .content .num {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}
.mobile .content .num .line {
  border-bottom: 1px solid #000;
  margin: 20px 0;
  width: 30%;
}
.mobile .content .num .box {
  width: 211px;
  text-align: center;
}
.mobile .content .num .box .one {
  letter-spacing: 3px;

  font-size: 46px;
  font-weight: 600;
}
.mobile .content .num .box .two {
  letter-spacing: 2px;

  margin-top: 10px;
  font-size: 14px;
}
.mobile .content .num .box .one span {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
}
.mobile .content .img {
  margin-top: 76px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile .content .img img {
  width: 100%;
  padding-bottom: 10px;
}
.mobile {
  display: none;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }
  .about {
    display: none;
  }
  .about .header {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .about {
    display: block;
  }
}
